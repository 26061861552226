import { authorized } from "../authorized";
import { UnifiedProvisionError, UnifiedProvisionPlanError } from "./constants";
import { bannerStatus, FetchError } from "./error";

export type Result<T, E extends FetchError = FetchError> =
  | { success: true; body: T }
  | { success: false; error: E | undefined };

export interface APIError {
  field?: string;
  message?: string;
  help?: string;
}

export interface APIResponse {
  errors?: APIError[];
  status: number;
}

export interface FetchUnifiedSearchResponse extends APIResponse {
  result: { id: number; type: "mp" | "unified" }[];
}

export type FetchIdentifierResult =
  | {
      success: true;
      login_type: string;
    }
  | {
      success: false;
      reason: string;
    };

export interface FetchIdentifierResponse extends APIResponse {
  login_type: string;
}

export const fetchIdentifier = async (
  username: string,
  bot_detection_token?: string
): Promise<FetchIdentifierResult> => {
  const baseURL = getBaseURL();
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    ...(bot_detection_token && { "Bot-Detection-Token": bot_detection_token }),
  };
  // Routing for SSO, Auth0, and Basic Auth
  // Build the request for the gandalf identifier endpoint
  // Call the gandalf identifier endpoint
  const response = await fetchWithTimeout(`${baseURL}/v3/public/identifier`, {
    timeout: 5000,
    method: "POST",
    headers,
    body: JSON.stringify({ username }),
  });
  if (!response) {
    return {
      success: false,
      reason: bannerStatus.GeneralIdentifierError.banner,
    };
  }
  let body: FetchIdentifierResponse;
  try {
    body = await response.json();
    if (response.status === 400) {
      if (body?.errors?.[0]?.message) {
        return {
          success: false,
          reason: body?.errors?.[0]?.message,
        };
      }
      return {
        success: false,
        reason: bannerStatus.GeneralIdentifierError.banner,
      };
    }
    if (response.status === 404) {
      return {
        success: false,
        reason: bannerStatus.SSONotSetUpError.banner,
      };
    }
    if (!response.ok) {
      return {
        success: false,
        reason: bannerStatus.GeneralIdentifierError.banner,
      };
    }
    return {
      success: true,
      login_type: body?.login_type,
    };
  } catch (error) {
    return {
      success: false,
      reason: bannerStatus.GeneralIdentifierError.banner,
    };
  }
};

export interface FetchSSOResponse extends APIResponse {
  idp_url: string;
  saml_request: string;
  relay_state: string;
}

export const fetchSSO = async (username: string): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/sso/saml/request/${encodeURIComponent(username)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });
};

export interface FetchAuthenticateTokenResponse extends APIResponse {
  password_reset_required?: boolean;
  setup_2fa_required?: boolean;
  token?: string;
}

export const fetchAuthenticateToken = async (
  username: string,
  password: string
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/tokens`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
};

export const deleteAuthenticateToken = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/tokens`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  });
};

export const fetchTeammate = async (token: string, username: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/teammates/${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchAccountProfile = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/account/profile_v2`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchFriendlyName = async (token: string, friendlyName: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  const requestBody = {
    friendly_name: friendlyName,
  };

  return fetch(`${baseURL}/v3/account/profile_v2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });
};

export const fetchUserType = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/user/type`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export interface FetchTwoFAResponse extends APIResponse {
  is_verified?: boolean;
}

export const fetchTwoFASetting = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/public/access_settings/multifactor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export interface FetchSessionTokenResponse extends APIResponse {
  password_reset_required?: boolean;
  setup_2fa_required?: boolean;
  token?: string;
}

// Fetch the session token and navigate to Mako.
export const fetchSessionToken = async (
  access_token: string
): Promise<Result<FetchSessionTokenResponse>> => {
  const baseURL = getBaseURL();
  const response = await fetchWithTimeout(`${baseURL}/v3/sessions`, {
    timeout: 5000,
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
  if (!response) {
    return { success: false, error: new FetchError("failed to get a session response", {}) };
  }
  if (!response.ok && response.status === 401) {
    // Just means the user doesn't exist
    return { success: false, error: undefined };
  }
  let body;
  try {
    body = (await response.json()) as FetchSessionTokenResponse;
  } catch (error) {}
  if (response.status >= 200 && response.status < 300 && body) {
    // Means the user exists and is ready
    return { success: true, body: body };
  }
  // If anything else shows up throw a fetchError based on the return status.
  return {
    success: false,
    error: new FetchError("failed to fetch a session token", {
      status: response.status,
      errors: body?.errors,
    }),
  };
};

export interface FetchAcceptInviteResponse extends APIResponse {
  token: string;
  setup_2fa_required: boolean;
}

export interface FetchAcceptInviteBody {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
}

export const fetchAcceptInvite = async (
  token: string,
  body: FetchAcceptInviteBody
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/teammates/${token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export const fetchUnifiedSearch = async (access_token: string, id_token: string) => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/unified_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

export const fetchUnifiedSignup = async (
  access_token: string,
  id_token: string
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/unified_signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

/**
 * Call unified link endpoint to link two users together.
 * @param token
 * @param id_token
 */
export const fetchUnifiedLink = async (token: string, id_token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/unified_link`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

export type UnifiedProvisionPlanResponse =
  | {
      eligible: true;
      email: string;
      auth0_login_exists: boolean;
      rollout_phase: number;
      base: number;
    }
  | {
      eligible: false;
      reason: string;
      errors?: APIError[];
    };

/**
 * Call unified provision plan to check account linking eligibility.
 * @param token
 */
export const fetchUnifiedProvisionPlan = async (
  token: string
): Promise<UnifiedProvisionPlanResponse> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  const response = await fetch(`${baseURL}/v3/unified_provision/plan`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    const responseJson = await response.json();
    if (
      response.status === 403 &&
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].field === "2fa"
    ) {
      return {
        eligible: false,
        reason: UnifiedProvisionPlanError.VALIDATE_2FA_REQUIRED,
      };
    }
    return {
      eligible: false,
      reason: UnifiedProvisionPlanError.GENERAL_ERROR,
    };
  }
  return await response.json();
};

export type UnifiedProvisionResponse =
  | {
      success: true;
    }
  | {
      success: false;
      reason: UnifiedProvisionError;
      errors?: APIError[];
    };

/**
 * Call unified provision to link sendgrid account to twilio account.
 * @param token
 * @param id_token
 */
export const fetchUnifiedProvision = async (
  token: string,
  id_token: string
): Promise<UnifiedProvisionResponse> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  const response = await fetch(`${baseURL}/v3/unified_provision`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
  if (!response.ok) {
    const responseBody = await response.json();
    let error = UnifiedProvisionError.GENERAL_ERROR;
    if (
      response.status === 400 &&
      responseBody.errors[0].message === UnifiedProvisionError.USERNAME_IN_USE
    ) {
      error = UnifiedProvisionError.USERNAME_IN_USE;
    }
    if (
      response.status === 400 &&
      responseBody.errors[0].message === UnifiedProvisionError.AUTH0_EMAIL_MISMATCH
    ) {
      error = UnifiedProvisionError.AUTH0_EMAIL_MISMATCH;
    }
    return { success: false, reason: error };
  }
  return { success: true };
};

export interface RequestInitWithTimeout extends RequestInit {
  // Timeout
  timeout?: number;
}

export const fetchWithTimeout = async (
  input: string | URL | Request,
  options: RequestInitWithTimeout = {}
): Promise<Response> => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  if (input instanceof URL) {
    const response = await fetch(input, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } else if (input instanceof Request) {
    const response = await fetch(input, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } else {
    const response = await fetch(input as string, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  }
};

const getBaseURL = (): string => {
  return process.env.REACT_APP_API_BASE_URL || "https://api.sendgrid.com";
};
